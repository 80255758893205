<template>
  <div class="alliance-container">
    <div class="search">
      <a-form-model :model="searchFormData" class="allianceSerachForm">
        <a-button type="primary" @click="handleShowModel">上传数据文件</a-button>
        <div class="serachBox">
          <a-select
            style="width: 200px"
            allowClear
            @change="handleChange"
            v-model="searchFormData.id"
            placeholder="请选择广告平台"
            :filter-option="filterOption"
            showSearch
          >
            <a-select-option v-for="pitem in platListOptions" :key="pitem.id" :value="pitem.id">
              {{ pitem.name }}
            </a-select-option>
          </a-select>
          <a-select
            style="width: 200px"
            allowClear
            @change="handleChange"
            v-model="searchFormData.status"
            placeholder="请选择状态"
          >
            <a-select-option v-for="pitem in stsOptions" :key="pitem.value" :value="pitem.value">
              {{ pitem.label }}
            </a-select-option>
          </a-select>
          <a-button
            type="primary"
            @click="handleSerach"
          >查询</a-button
          >
        </div>
      </a-form-model>
    </div>
    <!-- 表格 -->
    <div class="table">
      <a-table
        :pagination="false"
        :columns="columns"
        :data-source="data"
        style="overflow: hidden"
        :scroll="{ x: 1200 }"
        class="m-t-20 m_table_cust p-0"
        :loading="spinLoading"
        bordered
      >
        <span slot="button" slot-scope="text, record">
          <a-button @click="handleShowModel('edit', record)"> 编辑</a-button>
        </span>
        <span slot="tags" slot-scope="tags, record">
          <div style="display: flex">
            <a-tag :color="record.status == 1 || record.status == 4 ? 'orange' : 'green'">
              {{ tags }}
            </a-tag>
          </div>
        </span>
        <div slot="description" slot-scope="record">
          <div v-if="record.status == 4">{{ record.description }}</div>
        </div>
      </a-table>
      <a-pagination
        class="m-t-20"
        v-if="data.length > 0"
        show-size-changer
        :current="querData.page"
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="handleCurrentPage"
        @showSizeChange="handleChangePageSize"
      />
      <m-empty v-else style="height: 500px">
        <template #description> 暂无数据 </template>
      </m-empty>
    </div>
    <!-- <a-spin /> -->
    <!-- 弹框 -->
    <a-modal v-model="showModelVisible" title="上传第三方数据" okText="提交审核" @ok="handleOk" @cancel="handleClose">
      <a-form-model ref="modelForm" :model="modelForm" v-bind="layout">
        <a-alert message="温馨提示: 一次最多上传31天数据" banner style="margin-bottom: 15px" />
        <a-form-model-item
          label="广告平台"
          prop="id"
          :rules="{
            required: true,
            message: '此项为必填项',
            trigger: 'change'
          }"
        >
          <a-select
            allowClear
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            v-model="modelForm.id"
            placeholder="请选择广告平台"
            @change="handleSelectChange"
          >
            <a-select-option v-for="pitem in platListOptions" :key="pitem.id" :value="pitem.id">
              {{ pitem.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="上传报表币种">
          <a-radio-group v-model="currency">
            <a-radio-button v-for="(item, inx) in radioArr" :key="inx" :value="item.value">
              {{ item.name }}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="上传报表">
          <a-upload
            :action="uploadUrl"
            :file-list="fileList"
            :before-upload="beforeUpload"
            :headers="header"
            :remove="handleRemove"
            :data="{ platId: modelForm.platId, currency: this.currency }"
          >
            <a-button type="primary"> 点击上传 </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="下载模板" v-if="this.currentAccessType == 1 || this.currentAccessType == 2">
          <a-button type="primary" @click="handleownloadTemplate">点击下载</a-button>
        </a-form-model-item>
        <a
          href="https://creative.medproad.com/medpro/html/rule.html"
          target="_blank"
          rel="noopener noreferrer"
        >如何在第三方后台下载数据?</a
        >
      </a-form-model>
      <template slot="footer">
        <a-button key="back" @click="handleClose"> 取消 </a-button>
        <a-button key="submit" type="primary" @click="handleOk"> 确定 </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { getThirdPartyrecord, platList, downloadTemplate, upload } from '@/api/thirdParty'
import mixDate from '@/mixins/initDate'
import { mapState } from 'vuex'
export default {
  name: 'ThirdParty',
  mixins: [mixDate],
  data () {
    return {
      searchFormData: {},
      visible: false,
      spinLoading: false,
      modelForm: {},
      platListOptions: [],
      uploadUrl: process.env.VUE_APP_API_BASE_URL + '/report/upload',
      stsOptions: [
        {
          label: '上传失败',
          value: 1
        },
        {
          label: '处理中',
          value: 2
        },
        {
          label: '处理成功',
          value: 3
        },
        {
          label: '处理失败',
          value: 4
        }
      ],
      currency: 'CNY',
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 }
      },
      radioArr: [
        { name: '人民币', value: 'CNY' },
        { name: '美元', value: 'USD' }
      ],
      data: [],
      columns: [
        {
          title: '广告平台',
          dataIndex: 'platform',
          width: '120px',
          align: 'left',
          key: 'platform'
        },
        {
          title: '账号名称',
          key: 'name',
          width: '200px',
          align: 'left',
          dataIndex: 'name'
        },
        {
          title: '数据开始时间',
          key: 'beginDate',
          width: '200px',
          align: 'left',
          dataIndex: 'beginDate'
        },
        {
          title: '数据结束时间',
          width: '200px',
          align: 'left',
          key: 'endDate',
          dataIndex: 'endDate'
        },
        {
          title: '上传时间',
          key: 'createTime',
          width: '200px',
          align: 'left',
          dataIndex: 'createTime'
        },
        {
          title: '完成时间',
          align: 'left',
          width: '200px',
          key: 'updateTime',
          dataIndex: 'updateTime'
        },
        {
          title: '状态',
          align: 'left',
          width: '100px',
          key: 'statusName',
          dataIndex: 'statusName',
          scopedSlots: { customRender: 'tags' }
        },
        {
          title: '失败原因',
          align: 'left',
          width: '200px',
          key: 'description',
          scopedSlots: { customRender: 'description' }
        }
      ],
      querData: {
        page: 1,
        limit: 10
      },
      showModelVisible: false,
      adPlanOptions: [],
      fileList: [],
      currentAccessType: undefined,
      total: 0
    }
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token
    }),
    header () {
      return {
        Authorization: this.token
      }
    }
  },
  created () {
    this.getThirdPartyrecordFn(this.querData)
    this.platListFn()
  },
  methods: {
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // 获取上传记录
    async getThirdPartyrecordFn (data) {
      const res = await getThirdPartyrecord(data)
      this.spinLoading = true
      if (res.code === 200 && res.data) {
        const tempData = res.data
        tempData.items.length !== 0 &&
          tempData.items.forEach((itme, index) => {
            itme.statusName = this.formatStsname(itme.status)
            itme.index = index
            itme.updateTime = itme.updateTime.replace('T', ' ')
            itme.createTime = itme.createTime.replace('T', ' ')
            itme.updateTime = itme.updateTime.replace('.000+0000', ' ')
            itme.createTime = itme.createTime.replace('.000+0000', ' ')
          })
        this.data = tempData.items || []
      }
      this.total = (res.data && res.data.total) || 0
      this.spinLoading = false
    },
    // 获取下拉框数据
    async platListFn (data) {
      const res = await platList(data)
      if (res.code === 200 && res.data) {
        this.platListOptions = res.data
      }
    },
    // 下载模板
    async downloadTemplateFn () {
      const res = await downloadTemplate()
      if (!res.result) {
        const data = new Blob([res], {
          type: 'application/vnd.ms-excel'
        })
        const blobUrl = window.URL.createObjectURL(data)
        this.handleDownloadFile(blobUrl)
      }
    },
    handleDownloadFile (url) {
      const a = document.createElement('a')
      a.href = url
      a.download = `三方数据模板.xlsx`
      a.click()
    },
    formatStsname (key) {
      switch (key) {
        case 1:
          return '上传失败'
        case 2:
          return '处理中'
        case 3:
          return '处理成功'
        case 4:
          return '处理失败'
        default:
          return '--'
      }
    },
    // 提交审核
    async uploadFn (data) {
      const res = await upload(data)
      if (res.code === 200) {
        this.modelForm = {}
        const { id, status } = this.searchFormData
        this.$message.success('添加成功')
        this.getThirdPartyrecordFn({ ...this.querData, status, platId: id })
      } else {
        this.$message.error(res.msg)
      }
    },
    // 打开广告弹框
    handleShowModel () {
      this.showModelVisible = true
      this.currentAccessType = undefined
      this.fileList = []
      this.modelForm = {}
    },
    // 广告弹框OK
    handleOk () {
      this.$refs.modelForm.validate((validate) => {
        if (!validate) return false
        this.showModelVisible = false
        const { fileList } = this
        const { platId } = this.modelForm
        const formData = new FormData()
        fileList.forEach((file) => {
          formData.append('file', file)
          formData.append('platId', platId)
          formData.append('currency', this.currency)
        })
        this.uploadFn(formData)
        // 回显还是人民币
        this.currency = 'CNY'
      })
    },
    // 关闭广告弹框
    handleClose () {
      this.showModelVisible = false
      this.currentAccessType = undefined
      this.fileList = []
      this.modelForm = {}
    },
    // 弹框选择广告平台
    handleSelectChange () {
      const { id } = this.modelForm
      const { accessType } = this.platListOptions.find((item) => item.id === id)
      this.modelForm = {
        ...this.modelForm,
        platId: id
      }
      this.currentAccessType = accessType
    },
    // 下载
    handleownloadTemplate () {
      this.downloadTemplateFn()
    },
    beforeUpload (file) {
      this.fileList = [...this.fileList, file]
      return false
    },
    // 删除文件
    handleRemove (file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    // 搜索
    handleSerach () {
      this.querData.page = 1
      const { id, status } = this.searchFormData
      this.getThirdPartyrecordFn({ ...this.querData, status, platId: id })
    },
    handleChange (value) {
      if (!value) {
        this.handleSerach()
      }
    },
    // 分页
    handleCurrentPage (page) {
      this.querData.page = page
      const { id, status } = this.searchFormData
      this.getThirdPartyrecordFn({ ...this.querData, status, platId: id })
    },
    handleChangePageSize (current, size) {
      this.querData.page = 1
      this.querData.limit = size
      const { id, status } = this.searchFormData
      this.getThirdPartyrecordFn({ ...this.querData, status, platId: id })
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';
.text-right {
  text-align: right;
}
.m-b-0 {
  margin-bottom: 0px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-r-10 {
  margin-right: 10px;
}
.p-0 {
  padding: 0;
}
.alliance-container {
  padding: 0 10px 10px 10px;
  .search {
    padding: 20px;
    border-radius: 5px;
    background: #fff;
    margin-bottom: 10px;
  }
  .table {
    margin-top: 10px;
    border-radius: 5px;
    background: #fff;
    padding: 20px;
    height: 100%;
    min-height: 75vh;
  }
  .allianceSerachForm {
    display: flex;
    justify-content: space-between;
    .serachBox {
      width: 490px;
      display: flex;
      justify-content: space-around;
    }
  }
}
a{
  color: @primary-color;
}
.aRadioBtn();
::v-deep .ant-alert-message {
  color: #eda12e !important;
}
</style>
